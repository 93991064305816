import type { IMovie } from '~/types/movies';
import type { CookieRef } from '#app';
import type { ICookieCredits } from '~/types/common';
import useStatefulCookie from '~/composables/useStatefulCookies';

export const usePlayerHelper = () => {
  const credits: CookieRef<ICookieCredits | null> = useStatefulCookie('credits');
  const { movie } = storeToRefs(useMovieStore());
  const config = useRuntimeConfig();
  const isTargetKgDomain = useState('isTargetKgDomain');

  const tryOpenAndroidApp = () => {
    if (!credits.value?.access) return;
    const { access } = credits.value;
    location.href = `films.android.flex://result?token=${access}&slug=${movie.value?.slug}`;
  };

  const tryOpenIOSApp = () => {
    location.href = `kinoflex:open/film/${movie.value?.slug}/`;
  };

  const downloadAndroidApp = () => {
    location.assign(config.public.androidStore);
  };

  const downloadIOSApp = () => {
    location.assign(isTargetKgDomain.value
      ? 'https://testflight.apple.com/join/vnH159Yb'
      : 'https://apps.apple.com/ru/app/id6476960798'
    );
  };

  const redirectToAndroidApp = () => {
    if (!credits.value?.access) return;
    tryOpenAndroidApp();
    const clickedAt = +new Date();
    setTimeout(function () {
      if (+new Date() - clickedAt < 2000) {
        downloadAndroidApp();
      }
    }, 1800);
  };

  const redirectToIOSApp = () => {
    tryOpenIOSApp();
    const clickedAt = +new Date();
    setTimeout(function () {
      if (+new Date() - clickedAt < 2000) {
        downloadIOSApp();
      }
    }, 1800);
  };
  return {
    tryOpenAndroidApp,
    tryOpenIOSApp,
    downloadAndroidApp,
    downloadIOSApp,
    redirectToAndroidApp,
    redirectToIOSApp,
  };
};
