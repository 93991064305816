import useStatefulCookie from '~/composables/useStatefulCookies';
import type { CookieRef } from '#app';
import type { ICookieCredits } from '~/types/common';
import type { IPlayer } from '~/types/movies';
import { useNavigator } from '~/composables/useNavigator';

const PlayerModal = defineAsyncComponent(() => import('~/components/modals/player/Index.vue'));
const { onReveal: revealPlayer, onClose: closePlayer } = createModal(PlayerModal);

const ErrorModal = defineAsyncComponent(() => import('~/components/modals/player/ErrorModal.vue'));
const { onReveal: revealError } = createModal(ErrorModal);

const WatchingOptionsModal = defineAsyncComponent(
  () => import('~/components/modals/watchingOptions/WatchingOptions.vue'),
);
const { onReveal: showWatchingOptionsModal } = createModal(WatchingOptionsModal);

const startWatch = ref(false);
const playerParams = ref<IPlayer>({});

export const usePlayer = () => {
  const credits: CookieRef<ICookieCredits | null> = useStatefulCookie('credits');
  const { movie } = storeToRefs(useMovieStore());
  const { user, state } = storeToRefs(useAuthStore());
  const { isAndroid, isIOS } = useNavigator();
  const { redirectToAndroidApp, redirectToIOSApp } = usePlayerHelper();
  const { $pwa } = useNuxtApp();
  const isTargetKgDomain = useState('isTargetKgDomain');
  const { onReveal: revealAuth } = useAuth();

  const computedParams = computed<IPlayer>(() => ({
    tkn: credits.value?.access ? `JWT ${credits.value?.access}` : '',
    film: movie.value?.slug,
    uuid: user.value?.uuid,
    quality: user.value?.quality_name,
    parentOrigin: location.origin,
  }));

  const startWatching = (episodeId?: number, seasonId?: number) => {
    if (!user.value) return revealAuth();

    playerParams.value = { ...computedParams.value };
    document.getElementById('watch-options')?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    if (episodeId !== undefined && seasonId !== undefined) {
      playerParams.value.episode = episodeId;
      playerParams.value.season = seasonId;
    } else {
      playerParams.value.statusTimeline = true;
    }
    if (movie?.value?.is_available) setupPlayer();
    startWatch.value = false;
  };

  const setupPlayer = () => {
    if (isTargetKgDomain.value && (isAndroid.value || isIOS.value) && !$pwa?.isPWAInstalled) {
      showWatchingOptionsModal();
      return;
    }
    if ($pwa?.isPWAInstalled) return revealPlayer({ data: playerParams.value });
    if (isAndroid.value) return redirectToAndroidApp();
    if (isIOS.value) return redirectToIOSApp();
    revealPlayer({ data: playerParams.value });
  };

  const startWatchingTrailer = (season: number = 0, slug?: string) => {
    playerParams.value = { ...computedParams.value };
    playerParams.value.trailer = season;
    playerParams.value.film = slug || playerParams.value.film;
    revealPlayer({ data: playerParams.value });
  };

  const startWatchingStream = (link: string) => {
    playerParams.value = { ...computedParams.value };
    playerParams.value.external = link;
    revealPlayer({ data: playerParams.value });
  };

  return {
    playerParams,
    startWatch,
    closePlayer,
    revealError,
    startWatching,
    setupPlayer,
    startWatchingTrailer,
    startWatchingStream,
  };
};
